<template>
  <div class="card centered">
    <div class="card-header">
      <p class="card-header-title">
        {{ isNew ? $t('mtetype_create_title') : $t('mtetype_update_title') }}
      </p>
    </div>
    <div class="card-content">
      <b-field label="Name">
        <b-input v-model="mteType.name"></b-input>
      </b-field>
      <div class="card-content-row">
        <b-field label="Needs Master">
          <b-checkbox v-model="mteType.needsMaster" />
        </b-field>
        <b-field label="Is Master">
          <b-checkbox v-model="mteType.isMaster" />
        </b-field>
      </div>
    </div>
    <footer class="card-footer detached">
      <b-button @click="cancel()" outlined :loading="waitingForWrite">{{
        $t('cancel')
      }}</b-button>
      <b-button @click="save()" type="is-info">{{
        isNew ? $t('create') : $t('update')
      }}</b-button>
    </footer>
  </div>
</template>
<script>
import store from '../../store/index';

export default {
  data() {
    return { mteType: { name: '', needsMaster: false, isMaster: false } };
  },
  computed: {
    isNew() {
      return !this.mteType._id;
    },
    waitingForWrite() {
      return this.$store.state.waitingForWrite;
    },
  },
  async beforeRouteEnter(to, {}, next) {
    const idToLoad = to.params ? to.params.id : null;
    if (idToLoad) {
      await store.dispatch('getMteType', idToLoad);
    }
    next();
  },
  created() {
    if (this.$route.params && this.$route.params.id) {
      this.mteType = this.$store.state.mteType;
    }
  },
  methods: {
    cancel() {
      this.$router.replace({ name: 'admin-root' });
    },
    async save() {
      await this.$store.dispatch('saveMteType', this.mteType);
      this.$router.replace({ name: 'admin-root' });
    },
  },
};
</script>

<style lang="scss" scoped>
.card.centered {
  width: 90%;
  margin: 20px auto;
}
.card-content-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  & > * {
    flex: 1 1 auto;
    margin: 0;
  }
}
.card-footer.detached {
  display: flex;
  position: fixed;
  bottom: 5%;
  right: 0;
  margin-right: 5%;
  gap: 10px;
  & > * {
    width: 100px;
  }
}
</style>
