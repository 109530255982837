<template>
  <div class="task-container" :id="task._id">
    <TaskName :taskName="task.taskName" :id="task._id" />
    <div>
      <div id="table-title">
        <span>INSPECTION DIAGRAM</span>
        <span>
          <span v-if="task.documentNumber" class="document-info"
            >Document Number: <span>{{ task.documentNumber }}</span></span
          >
          <span v-if="task.revision" class="document-info"
            >Revision: <span>{{ task.revision }}</span></span
          >
        </span>
      </div>
      <diagram-builder
        class="dij-diagram-fullscreen"
        :taskProp="task"
        :show-toolbar="false"
        :show-list="false"
        :report-mode="true"
        @zoomChange="onZoomChange"
      />
    </div>

    <div class="table-measurements" v-if="task.lengthData.length > 0">
      LENGTH MEASUREMENTS
      <b-table :data="task.lengthData" :striped="true" :narrowed="true">
        <template slot-scope="props">
          <b-table-column field="label" label="Label">
            {{ props.row.label }}
          </b-table-column>
          <b-table-column field="description" label="Description">
            {{ props.row.description }}
          </b-table-column>
          <b-table-column
            v-if="props.row.values.length > 0"
            v-for="item in props.row.values"
            :field="item.name"
            :label="item.name"
            :class="'col-value' + colorValueDimLen(item.diff)"
          >
            {{ item.value }}
            <template v-if="item.diff && isInternalReport">
              <div>{{ item.diff }}</div>
            </template>
          </b-table-column>

          <b-table-column
            field="Expected value"
            label="Expected value"
            :visible="isInternalReport"
          >
            {{ props.row.expectedValue }}
          </b-table-column>

          <b-table-column
            field="tolerance1"
            label="Tolerance1"
            :visible="isInternalReport"
          >
            {{ props.row.tolerance1 }}
          </b-table-column>

          <b-table-column
            field="tolerance2"
            label="Tolerance2"
            :visible="isInternalReport"
          >
            {{ props.row.tolerance2 }}
          </b-table-column>

          <b-table-column field="unit" label="Unit">
            {{ props.row.unit }}
          </b-table-column>

          <b-table-column
            field="lastModifiedBy"
            label="Inspector"
            :visible="isInternalReport"
          >
            {{ props.row.lastModifiedBy }}
          </b-table-column>

          <b-table-column field="lastModifiedAt" label="Date">
            {{ props.row.lastModifiedAt }}
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div class="table-measurements" v-if="task.diameterData.length > 0">
      DIAMETER MEASUREMENTS
      <b-table :data="task.diameterData" :striped="true" :narrowed="true">
        <template slot-scope="props">
          <b-table-column field="label" label="Label">
            {{ props.row.label }}
          </b-table-column>
          <b-table-column field="description" label="Description">
            {{ props.row.description }}
          </b-table-column>
          <b-table-column
            v-if="props.row.values.length > 0"
            v-for="item in props.row.values"
            :field="item.name"
            :label="item.name"
            :class="'col-value' + colorValueDimLen(item.diff)"
          >
            {{ item.value }}
            <template v-if="item.diff && isInternalReport">
              <div>{{ item.diff }}</div>
            </template>
          </b-table-column>

          <b-table-column
            field="expectedValue"
            label="Expected value"
            :visible="isInternalReport"
          >
            {{ props.row.expectedValue }}
          </b-table-column>

          <b-table-column
            field="tolerance1"
            label="Tolerance1"
            :visible="isInternalReport"
          >
            {{ props.row.tolerance1 }}
          </b-table-column>

          <b-table-column
            field="tolerance2"
            label="Tolerance2"
            :visible="isInternalReport"
          >
            {{ props.row.tolerance2 }}
          </b-table-column>

          <b-table-column field="unit" label="Unit">
            {{ props.row.unit }}
          </b-table-column>

          <b-table-column
            field="lastModifiedBy"
            label="Inspector"
            :visible="isInternalReport"
          >
            {{ props.row.lastModifiedBy }}
          </b-table-column>

          <b-table-column field="lastModifiedAt" label="Date">
            {{ props.row.lastModifiedAt }}
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div class="table-measurements" v-if="task.runoutData.length > 0">
      RUNOUT MEASUREMENTS
      <b-table :data="task.runoutData" :striped="true" :narrowed="true">
        <template slot-scope="props">
          <b-table-column field="label" label="Label">
            {{ props.row.label }}
          </b-table-column>
          <b-table-column field="description" label="Description">
            {{ props.row.description }}
          </b-table-column>
          <b-table-column
            v-if="props.row.values.length > 0"
            v-for="item in props.row.values"
            :field="item.name"
            :label="item.name"
            :class="
              'col-value' +
              colorValueDimLen(
                item.value,
                props.row.expectedValue,
                props.row.tolerance1,
                props.row.tolerance2
              )
            "
          >
            {{ item.value }}
          </b-table-column>

          <b-table-column
            field="expectedValue"
            label="Expected value"
            :visible="isInternalReport"
          >
            {{ props.row.expectedValue }}
          </b-table-column>

          <b-table-column field="unit" label="Unit">
            {{ props.row.unit }}
          </b-table-column>

          <b-table-column
            field="lastModifiedBy"
            label="Inspector"
            :visible="isInternalReport"
          >
            {{ props.row.lastModifiedBy }}
          </b-table-column>

          <b-table-column field="lastModifiedAt" label="Date">
            {{ props.row.lastModifiedAt }}
          </b-table-column>
        </template>
      </b-table>
    </div>
    <div
      class="table-measurements"
      v-if="runoutDataSingleMeasurment.length > 0"
    >
      <RunoutReportTable
        :runoutData="runoutDataSingleMeasurment"
        :isInternalReport="isInternalReport"
        :colorValueDimLen="colorValueDimLen"
      />
    </div>

    <div
      class="table-measurements"
      v-if="runoutDataMultipleMeasurment.length > 0"
    >
      <RunoutReportTable
        :runoutData="runoutDataMultipleMeasurment"
        :isInternalReport="isInternalReport"
        :colorValueDimLen="colorValueDimLen"
      />
    </div>

    <div class="table-measurements" v-if="task.visualData.length > 0">
      VISUAL INSPECTION
      <b-table :data="task.visualData" :striped="true" :narrowed="true">
        <template slot-scope="props">
          <b-table-column
            field="label"
            label="Label"
            :class="
              props.row.condition !== 'FINE' && isInternalReport && 'red-value'
            "
          >
            {{ props.row.label }}
          </b-table-column>
          <b-table-column field="visualName" label="Visual Name">
            {{ props.row.visualName }}
          </b-table-column>
          <b-table-column field="description" label="Description">
            {{ props.row.description }}
          </b-table-column>
          <b-table-column
            field="condition"
            label="Condition"
            :class="
              props.row.condition !== 'FINE' && isInternalReport && 'red-value'
            "
          >
            {{ props.row.condition }}
          </b-table-column>

          <b-table-column
            field="value"
            label="	Severity"
            :class="
              props.row.condition !== 'FINE' && isInternalReport && 'red-value'
            "
          >
            {{ props.row.value }}
          </b-table-column>

          <b-table-column
            field="lastModifiedBy"
            label="Inspector"
            :visible="isInternalReport"
          >
            {{ props.row.lastModifiedBy }}
          </b-table-column>

          <b-table-column field="lastModifiedAt" label="Date">
            {{ props.row.lastModifiedAt }}
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div class="table-measurements" v-if="task.genericData.length > 0">
      GENERIC MEASUREMENTS
      <b-table :data="task.genericData" :striped="true" :narrowed="true">
        <template slot-scope="props">
          <b-table-column field="label" label="Label">
            {{ props.row.label }}
          </b-table-column>
          <b-table-column field="measurementName" label="Measurement Name">
            {{ props.row.measurementName }}
          </b-table-column>
          <b-table-column field="description" label="Description">
            {{ props.row.description }}
          </b-table-column>
          <b-table-column field="value" label="Value">
            {{ props.row.value }}
          </b-table-column>

          <b-table-column
            field="lastModifiedBy"
            label="Inspector"
            :visible="isInternalReport"
          >
            {{ props.row.lastModifiedBy }}
          </b-table-column>

          <b-table-column field="lastModifiedAt" label="Date">
            {{ props.row.lastModifiedAt }}
          </b-table-column>
        </template>
      </b-table>
    </div>
    <Attachments :task-prop="task" :isFreeFinding="true" />
    <Findings :task-prop="task" />
    <Attachments :task-prop="task" />

    <div id="divider" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import DiagramBuilder from '../../../components/DiagramBuilder/DiagramBuilder';
import Findings from '../components/Findings';
import Attachments from '../components/Attachments';
import TaskName from '../components/TaskName';
import RunoutReportTable from '../components/RunoutReportTable';

export default {
  name: 'Diagram',
  props: {
    taskProp: {
      type: Object,
      required: true,
    },
  },

  components: {
    DiagramBuilder,
    Findings,
    Attachments,
    TaskName,
    RunoutReportTable,
  },
  computed: {
    task() {
      return this.taskProp;
    },

    lengthData() {
      return this.parseLengthData(this.task);
    },

    diameterData() {
      return this.parseDiameterData(this.task);
    },

    runoutData() {
      let runoutData = this.parseRunoutData(this.task);

      if (Array.isArray(runoutData)) return [runoutData];
      runoutData = Object.values(runoutData);
      return runoutData;
    },

    genericData() {
      return this.parseGenericData(this.task);
    },

    visualData() {
      return this.parseVisualData(this.task);
    },

    migratedTask() {
      const { task } = this;
      if (task.childConfig.length === 0) return task;

      if (task.childConfig[0].childConfig[1].src) {
        const diagram = {
          inspections: [],
          annotations: [],
          src: task.childConfig[0].childConfig[1].src,
        };
        if (
          task.childConfig[0].childConfig[1].childConfig &&
          task.childConfig[0].childConfig[1].childConfig.length !== 0
        ) {
          const diagramInspectionData = {};
          task.childConfig[0].childConfig[0].childConfig.forEach((element) => {
            diagramInspectionData[element.id] = element;
          });

          task.childConfig[0].childConfig[1].childConfig.forEach((element) => {
            const inspection = {};
            const importedInspection =
              diagramInspectionData[element.element_id];
            inspection.id = element.element_id;

            if (importedInspection) {
              // General Data
              inspection.number = element.label;
              inspection.type = importedInspection.measurement_type
                ? importedInspection.measurement_type
                : importedInspection.childConfig[0].measurement_type;
              inspection.unit =
                importedInspection.unit === 'inch' ? 'in' : 'mm';
              inspection.isoName = importedInspection.iso_name;
              inspection.isoGrade = importedInspection.iso_grade_selected;
              inspection.expectedValue = parseFloat(
                importedInspection.expectedValue
              );
              const toleranceTypeTranslationTable = [
                'normal',
                'positive',
                'negative',
              ];
              inspection.toleranceType =
                toleranceTypeTranslationTable[importedInspection.toleranceType];
              inspection.positiveTolerance = parseFloat(
                importedInspection.positiveTolerance
              );

              let negativeTolerance = parseFloat(
                importedInspection.negativeTolerance
              );

              if (negativeTolerance > 0) {
                negativeTolerance *= -1;
              }

              inspection.negativeTolerance = negativeTolerance;

              if (inspection.type === 'generic') {
                // Get the measurement name for generic measurements
                inspection.measurementName =
                  importedInspection.generic_measurement_name;
              } else if (inspection.type === 'runout') {
                // Get the tolerance for runout measurements
                inspection.tolerance = importedInspection.tolerance;
                inspection.directionalUnit =
                  importedInspection.direction || 'deg';
              }
              inspection.measurements = [];

              // Measurements handlers
              if (inspection.type === 'visual') {
                inspection.measurementCount = 4;
                let success = false;
                if (importedInspection.childConfig[0].type === '0') {
                  inspection.measurements.push('ok');
                  inspection.measurements.push('ok');
                  inspection.measurements.push('ok');
                  inspection.measurements.push('ok');
                  success = true;
                } else if (importedInspection.childConfig[0].visualConditions) {
                  const { visualConditions } =
                    importedInspection.childConfig[0];
                  const rubbedCondition = visualConditions.find(
                    (visualCondition) => visualCondition.name === 'Rubbed'
                  );
                  const damagedCondition = visualConditions.find(
                    (visualCondition) => visualCondition.name === 'Damaged'
                  );
                  const pittedCondition = visualConditions.find(
                    (visualCondition) => visualCondition.name === 'Pitted'
                  );
                  const erodedCondition = visualConditions.find(
                    (visualCondition) => visualCondition.name === 'Eroded'
                  );
                  if (rubbedCondition && rubbedCondition.radioSelected) {
                    inspection.measurements.push(
                      `${rubbedCondition.radioSelected.toLowerCase()}`
                    );
                  }
                  /* else {
                    inspection.measurements.push('ok');
                  } */
                  if (damagedCondition && damagedCondition.radioSelected) {
                    inspection.measurements.push(
                      `${damagedCondition.radioSelected.toLowerCase()}`
                    );
                  }
                  /* else {
                    inspection.measurements.push('ok');
                  } */
                  if (pittedCondition && pittedCondition.radioSelected) {
                    inspection.measurements.push(
                      `${pittedCondition.radioSelected.toLowerCase()}`
                    );
                  }
                  /* else {
                    inspection.measurements.push('ok');
                  } */
                  if (erodedCondition && erodedCondition.radioSelected) {
                    inspection.measurements.push(
                      `${erodedCondition.radioSelected.toLowerCase()}`
                    );
                  }
                  /* else {
                    inspection.measurements.push('ok');
                  } */

                  if (visualConditions.length <= 4) {
                    success = true;
                  }
                }

                if (!success) {
                  throw new Error(
                    `Failed to parse inspection: ${JSON.stringify(
                      importedInspection
                    )}`
                  );
                }
              } else {
                // Handle measurements
                inspection.measurementCount = importedInspection.numberOfPoints;
                if (!inspection.measurementCount) {
                  inspection.measurementCount = 1;
                }

                // Convert measurements
                // There is a special case, which is runout with zero measurements that will have 2 measurements to migrate
                const totalMeasurements = Math.max(
                  inspection.measurementCount,
                  importedInspection.inputValues.length
                );
                let latestDate = 0;
                for (let i = 0; i < totalMeasurements; i++) {
                  const measurement = importedInspection.inputValues[i];
                  if (
                    measurement &&
                    latestDate < measurement.bubbleModifierDate
                  ) {
                    latestDate = measurement.bubbleModifierDate;
                  }
                  inspection.measurements.push(
                    measurement ? parseFloat(measurement.value) : null
                  );
                }
                if (latestDate === 0) {
                  inspection.updatedAt = Date.now();
                } else {
                  inspection.updatedAt = latestDate;
                }
              }

              // Convert images
              inspection.images = [];
              const imageContainer = importedInspection.childConfig.find(
                (innerElement) =>
                  innerElement.tagName === 'EMF-PHOTOCOMPONENT' &&
                  innerElement.image
              );
              if (imageContainer) {
                for (let i = 0; i < imageContainer.length; i++) {
                  const image = imageContainer[i];
                  inspection.images.push({
                    name: image.name,
                    url: image.path,
                  });
                }
              }

              // Convert comment
              inspection.inspectorComment =
                importedInspection.findingsByInspector;
            } else if (element.tagName === 'INPUT-DIAGRAM-ITEM-ARROW') {
              inspection.type = 'arrow';
            } else if (element.tagName === 'INPUT-DIAGRAM-ITEM-TEXT') {
              inspection.type = 'text';
              inspection.text = element.label;
              inspection.textSize = element.size;
            } else {
              // Possible corrupted data
              inspection.type = 'unknown';
            }
            inspection.orientation = '0';
            if (element.verticalUp) {
              inspection.orientation = '0';
            } else if (element.verticalDown) {
              inspection.orientation = '1';
            } else if (element.horizontalLeft) {
              inspection.orientation = '2';
            } else if (element.horizontalRight) {
              inspection.orientation = '3';
            }

            if (inspection.type === 'length') {
              const firstPoint = element.bottomLeftCoord;
              const secondPoint = element.topLeftCoord;
              const thirdPoint = element.topRightCoord;
              const fourthPoint = element.bottomRightCoord;

              // Below code arise a problem within the report module for the length measurement
              // For time being will be kept untill the fixed version is tested

              // let firstPoint;
              // let secondPoint;
              // let thirdPoint;
              // let fourthPoint;
              // if (
              //   element.points_to.x === element.bottomLeftCoord.x &&
              //   element.points_to.y === element.bottomLeftCoord.y
              // ) {
              //   firstPoint = element.bottomLeftCoord;
              //   secondPoint = element.topLeftCoord;
              //   thirdPoint = element.topRightCoord;
              //   fourthPoint = element.bottomRightCoord;
              // } else if (
              //   element.points_to.x === element.bottomRightCoord.x &&
              //   element.points_to.y === element.bottomRightCoord.y
              // ) {
              //   firstPoint = element.bottomRightCoord;
              //   secondPoint = element.topRightCoord;
              //   thirdPoint = element.topLeftCoord;
              //   fourthPoint = element.bottomLeftCoord;
              // } else if (
              //   element.points_to.x === element.topLeftCoord.x &&
              //   element.points_to.y === element.topLeftCoord.y
              // ) {
              //   firstPoint = element.topLeftCoord;
              //   secondPoint = element.bottomLeftCoord;
              //   thirdPoint = element.bottomRightCoord;
              //   fourthPoint = element.topRightCoord;
              // } else {
              //   firstPoint = element.topRightCoord;
              //   secondPoint = element.bottomRightCoord;
              //   thirdPoint = element.bottomLeftCoord;
              //   fourthPoint = element.topLeftCoord;
              // }

              const circlePositionRatio = element.circlePosition;

              const dx = thirdPoint.x - secondPoint.x;
              const dy = thirdPoint.y - secondPoint.y;

              const circlePosition = {
                x: secondPoint.x + dx * circlePositionRatio,
                y: secondPoint.y + dy * circlePositionRatio,
              };

              inspection.firstPoint = firstPoint;
              inspection.secondPoint = secondPoint;
              inspection.thirdPoint = thirdPoint;
              inspection.fourthPoint = fourthPoint;

              inspection.point = circlePosition;
            } else if (inspection.type === 'text') {
              inspection.point = element.points_to;
            } else if (inspection.type === 'arrow') {
              // Old arrows need a convertion to switch from 3 points to 2 points

              // Calculate the center of the arrow
              const center = {
                x: (element.pointA.x + element.pointB.x) / 2,
                y: (element.pointA.y + element.pointB.y) / 2,
              };

              // Calculate the distance between the 3rd point and the center
              const dx = element.points_to.x - center.x;
              const dy = element.points_to.y - center.y;

              // Translate the points
              inspection.startPoint = {
                x: element.pointA.x + dx,
                y: element.pointA.y + dy,
              };
              inspection.point = {
                x: element.pointB.x + dx,
                y: element.pointB.y + dy,
              };
            } else {
              inspection.point = element.points_to;
            }

            if (inspection.type === 'unknown') {
              console.warn('Failed to parse item', element, task);
            } else if (
              inspection.type === 'text' ||
              inspection.type === 'arrow'
            ) {
              diagram.annotations.push(inspection);
            } else {
              diagram.inspections.push(inspection);
            }
          });
        }
        task.diagram = diagram;
      }

      return task;
    },

    isInternalReport() {
      return this.$store.state.ReportsModule.isInternalReport;
    },

    runoutDataSingleMeasurment() {
      return this.task.runoutData.filter(
        (inspection) => !inspection.hasMultipleMeasurements
      );
    },

    runoutDataMultipleMeasurment() {
      return this.task.runoutData.filter(
        (inspection) => inspection.hasMultipleMeasurements
      );
    },
  },
  methods: {
    colorValueDimLen(value) {
      if (!this.isInternalReport) return '';
      if (value !== undefined) return ' red-value';
      return '';
    },

    isNOTInTolarances(value, tolerance) {
      if (!this.isInternalReport) return false;
      if (isNaN(value)) return false;
      const negativeTolerance = Math.abs(tolerance) * -1;
      const positiveTolerance = Math.abs(tolerance);
      const res =
        parseFloat(value) >= negativeTolerance &&
        parseFloat(value) <= positiveTolerance;
      return !res;
    },
    onZoomChange: debounce(function (zoomLevel) {
      const updatedTask = { ...this.task };
      updatedTask.zoomLevel = zoomLevel;

      if (updatedTask.lengthData) delete updatedTask.lengthData;
      if (updatedTask.diameterData) delete updatedTask.diameterData;
      if (updatedTask.runoutData) delete updatedTask.runoutData;
      if (updatedTask.visualData) delete updatedTask.visualData;
      if (updatedTask.genericData) delete updatedTask.genericData;
      if (updatedTask.attachmentData) delete updatedTask.attachmentData;

      this.$store.dispatch('updateTask', updatedTask);
    }, 500),

    // getMesurmentPoints(runoutItems) {
    //   if (runoutItems[0].tir) return '';
    //   return runoutItems[0].values.length;
    // },
  },
};
</script>

<style scoped lang="scss">
#task-header {
  height: 35px;
  font-family: 'Siemens Sans';
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-top: 35px;
}

#table-title {
  font-family: 'Siemens Sans';
  font-size: 18px;
  background-color: white;
  padding: 10px;
}

#divider {
  height: 10px;
  background-color: #ccc;
  width: 100%;
  margin-top: 20px;
}

#table-inspection {
  margin-top: 20px;
  background-color: #fff;
  padding: 10px;
  font-family: 'Siemens Sans';
  font-size: 18px;
}

.table-measurements {
  background-color: #fff;
  padding: 10px;
  font-family: 'Siemens Sans';
  font-size: 18px;
  margin-top: 20px;
}

.dij-diagram-fullscreen {
  height: calc(100vh - 50px - 50px);
}

.red-value {
  color: #e87061;
}

#table-title {
  font-family: 'Siemens Sans';
  font-size: 18px;
  background-color: white;
  padding: 10px;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ccc;
  display: flex;
  justify-content: space-between;
}

.document-info {
  border-left: solid;
  border-width: 1px;
  padding: 5px;
  margin-left: 10px;
  border-color: #ccc;
}

.document-info span {
  color: #aaa;
}

.col-value {
  white-space: pre-line;
}
</style>
