<template>
  <div style="max-height: 100%">
    <div class="header">
      <div style="display: inline-flex">
        <div
          class="list-type"
          :class="!showFavorites ? 'active' : ''"
          @click="listFavorites(false)"
        >
          {{ productListText }}
        </div>
        <div
          class="list-type"
          :class="showFavorites ? 'active' : ''"
          @click="listFavorites(true)"
          v-if="type === 'editor'"
        >
          {{ $t('favorite_list') }}
        </div>
      </div>
      <b-loading
        :is-full-page="true"
        :active.sync="isLoading"
        :can-cancel="false"
      ></b-loading>
      <div class="right-section">
        <div v-if="isLocationFilterAvailable" class="filter-location">
          <filter-location
            :locations="selectedLocations"
            @update="updateLocation"
          >
          </filter-location>
        </div>
        <div class="search">
          <form v-on:submit.prevent="getJobs">
            <b-field>
              <b-input
                :placeholder="$t('search')"
                type="search"
                icon="magnify"
                icon-clickable
                @icon-click="getJobs"
                class="search-input"
                v-model="search"
              >
              </b-input>
            </b-field>
          </form>
        </div>
        <router-link
          :to="'/view/' + type + '/create'"
          tag="button"
          class="btn-primary"
          v-if="type === 'editor' || type === 'publisher'"
          >{{ $t('add_product') }}</router-link
        >
        <template v-if="isDeletedJobsAvailable">
          <b-button
            type="is-success"
            size="is-large"
            icon-left="recycle"
            class="dij-jobs-recycle-bin"
            @click="showDeletedJobs"
          />
          <b-modal
            :active="isDeletedJobsVisible"
            has-modal-card
            trap-focus
            class="dij-jobs-deleted-modal"
            @close="hideDeletedJobs"
          >
            <div class="modal-card">
              <header class="modal-card-head">
                <b-icon icon="recycle" size="is-large" />
                <p class="modal-card-title">{{ $t('recycle_bin') }}</p>
              </header>
              <section class="modal-card-body">
                <template v-if="deletedJobs.length > 0">
                  <b-table :data="deletedJobs">
                    <template slot-scope="props">
                      <b-table-column field="title" :label="$t('title')">
                        {{ props.row.title }}
                      </b-table-column>
                      <b-table-column
                        v-if="type !== 'editor'"
                        field="project_number"
                        :label="$t('project_number')"
                      >
                        {{ props.row.projectInfoText }}
                      </b-table-column>
                      <b-table-column
                        v-if="type === 'editor'"
                        field="document_number"
                        :label="$t('document_number')"
                      >
                        {{ props.row.documentNumber }}
                      </b-table-column>
                      <b-table-column field="owner" :label="$t('owner')">
                        {{ props.row.prodCreatedByUser.fullname }}
                      </b-table-column>
                      <b-table-column
                        field="deletedBy"
                        :label="$t('deleted_by')"
                      >
                        {{ props.row.deletedBy }}
                      </b-table-column>
                      <b-table-column
                        field="deletedAt"
                        :label="$t('deleted_at')"
                      >
                        {{ convertEpochToDate(props.row.deletedAt) }}
                      </b-table-column>
                      <b-table-column :label="$t('actions')">
                        <b-tooltip label="Recover" position="is-top">
                          <b-button
                            class="dij-recycle-bin-recover"
                            type="is-primary"
                            icon-left="backup-restore"
                            @click="undelete(props.row._id)"
                          />
                        </b-tooltip>
                      </b-table-column>
                    </template>
                  </b-table>
                </template>
                <template v-else>
                  {{ $t('recycle_bin_empty') }}
                </template>
              </section>
            </div>
          </b-modal>
        </template>
      </div>
    </div>

    <div class="items">
      <div class="items-container">
        <b-message
          :active.sync="showNoFiltersMessage"
          title="Warning"
          type="is-warning"
          aria-close-label="Close message"
          auto-close
          :duration="10000"
          >{{ noFiltersMessage }}
        </b-message>
        <b-table
          ref="tableRef"
          :mobile-cards="isMobile && mobileView"
          :data="updatedTableData"
          :loading="waitingForRead"
          :hoverable="true"
          :row-class="(row, index) => 'table-list-row'"
          @click="goToJob"
          paginated
          :pagination-simple="true"
          :per-page="perPage"
          :current-page="page"
          :rounded="true"
          @page-change="onPageChange"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
        >
          <template slot-scope="props">
            <b-table-column
              :class="props.row.completenessClass"
              field="picture"
              width="40"
              searchable
            >
              <template
                v-if="isSearchActive && !isMobileDevice"
                slot="searchable"
                slot-scope="props"
              >
                <b-button
                  :v-if="isSearchActive"
                  @click="clearFilters"
                  size="is-small"
                  icon-left="backspace-reverse"
                >
                  Clear filters
                </b-button>
              </template>
              <div
                class="completeness-inner"
                :style="
                  'height:' +
                  props.row.completenessValueFiltered +
                  '%!important;'
                "
              >
                &nbsp;
              </div>
              <div class="image-container">
                <b-tooltip
                  :label="props.row.completenessPercentage"
                  type="is-dark"
                  v-if="type !== 'editor'"
                >
                  <img :src="props.row.picture" class="thumbnail" />
                </b-tooltip>
                <img v-else :src="props.row.picture" class="thumbnail" />
                <div
                  class="image-overlay"
                  v-if="props.row.includesRepairAssessment"
                >
                  <b-icon icon="hammer-wrench" type="is-info is-light" />
                </div>
              </div>
            </b-table-column>
            <b-table-column
              field="product_line"
              :label="$t('product_line')"
              width="90"
              searchable
            >
              <template
                v-if="isSearchActive && !isMobileDevice"
                slot="searchable"
                slot-scope="props"
              >
                <div :v-if="isSearchActive">
                  <b-input
                    v-if="isSearchActive"
                    v-model="filterObject['product_line']"
                    placeholder="Filter"
                    size="is-small"
                  />
                </div>
              </template>
              {{ props.row.product_line }}
            </b-table-column>
            <b-table-column
              v-for="columnData in columnsData"
              :field="columnData"
              :label="formatColumnName(columnData)"
              :key="columnData"
              searchable
            >
              <div
                v-if="isSearchActive && !isMobileDevice"
                slot="searchable"
                slot-scope="props"
              >
                <div v-if="isSearchActive && !isMobileDevice">
                  <b-input
                    v-if="isSearchActive"
                    v-model="filterObject[props.column.field]"
                    placeholder="Filter"
                    size="is-small"
                  />
                </div>
              </div>
              <p
                :class="
                  props.row[columnData] && props.row[columnData].length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row[columnData] }}
              </p>
            </b-table-column>
            <b-table-column
              v-if="type === 'editor'"
              field="sapReference"
              label="SAP Reference"
              style="min-width: 110px"
              searchable
            >
              <template
                v-if="isSearchActive && !isMobileDevice"
                slot="searchable"
                slot-scope="props"
              >
                <div :v-if="isSearchActive">
                  <b-input
                    v-if="isSearchActive"
                    v-model="filterObject['sapReference']"
                    placeholder="Filter"
                    size="is-small"
                  />
                </div>
              </template>
              <p
                :class="
                  props.row.sapReference && props.row.sapReference.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.sapReference }}
              </p>
            </b-table-column>
            <b-table-column
              v-if="type === 'editor'"
              field="date"
              label="Current Owner"
              style="min-width: 110px"
              searchable
            >
              <template
                v-if="isSearchActive && !isMobileDevice"
                slot="searchable"
                slot-scope="props"
              >
                <div :v-if="isSearchActive">
                  <b-input
                    v-if="isSearchActive"
                    v-model="filterObject['created_by']"
                    placeholder="Filter"
                    size="is-small"
                  />
                </div> </template
              >{{ props.row.created_by }}
            </b-table-column>
            <b-table-column
              v-if="type === 'editor'"
              field="date"
              :label="$t('date')"
              style="min-width: 110px"
              searchable
            >
              <template
                v-if="isSearchActive && !isMobileDevice"
                slot="searchable"
                slot-scope="props"
              >
                <div :v-if="isSearchActive">
                  <b-input
                    v-if="isSearchActive"
                    v-model="filterObject['date']"
                    placeholder="Filter"
                    size="is-small"
                  />
                </div>
              </template>

              {{ props.row.date }}</b-table-column
            >
            <b-table-column
              v-else
              field="date"
              label="Current Owner / Date"
              style="min-width: 110px"
              searchable
            >
              <template
                :hidden="!isSearchActive"
                v-if="isSearchActive"
                slot="searchable"
                slot-scope="props"
              >
                <div :v-if="isSearchActive">
                  <b-input
                    v-if="isSearchActive"
                    v-model="filterObject['owner_date']"
                    placeholder="Filter"
                    size="is-small"
                  />
                </div>
              </template>
              {{ props.row.owner_date }}</b-table-column
            >
            <b-table-column
              custom-key="actions"
              v-if="type === 'editor' || type === 'publisher'"
              class="options-column"
            >
              <div
                v-if="type === 'editor'"
                @click="
                  addToFavorites(
                    props.row.id,
                    props.row.nameId,
                    props.row.favoriteCount
                  )
                "
              >
                <b-icon
                  icon="star"
                  :class="favoriteStarClass(props.row.nameId)"
                >
                </b-icon>
                {{ props.row.favoriteCount }}
              </div>
              <div @click="optionsClicked = true">
                <b-dropdown
                  aria-role="list"
                  :position="props.index > 1 ? 'is-top-left' : 'is-bottom-left'"
                >
                  <button class="button options-more-btn" slot="trigger">
                    <b-icon icon="dots-horizontal"></b-icon>
                  </button>

                  <b-dropdown-item
                    aria-role="listitem"
                    v-if="userOrAdmin(props.row)"
                    @click="editJob(props.row.id)"
                  >
                    <div class="media">
                      <b-icon class="media-left" icon="pencil"></b-icon>
                      <div class="media-content">
                        <h3>{{ $t('edit') }}</h3>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item
                    aria-role="listitem"
                    v-if="userOrAdmin(props.row)"
                    @click="deleteJob(props.row.id, props.row.product_line)"
                  >
                    <div class="media">
                      <b-icon class="media-left" icon="delete"></b-icon>
                      <div class="media-content">
                        <h3>{{ $t('delete') }}</h3>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="type === 'editor'"
                    aria-role="listitem"
                    @click="duplicate(props.row.id)"
                  >
                    <div class="media">
                      <b-icon class="media-left" icon="content-copy"></b-icon>
                      <div class="media-content">
                        <h3>{{ $t('duplicate') }}</h3>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item
                    aria-role="listitem"
                    v-if="userOrAdmin(props.row)"
                    @click="copyFrom(props.row.id)"
                  >
                    <div class="media">
                      <b-icon
                        class="media-left"
                        icon="swap-horizontal"
                      ></b-icon>
                      <div class="media-content">
                        <h3>{{ $t('copyfrom') }}</h3>
                      </div>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-table-column>
          </template>
          <template slot="empty" v-if="!waitingForRead">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                </p>
                <p>{{ $t('nothing_here') }}.</p>
              </div>
            </section>
          </template>
        </b-table>
        <!-- <b-button
          v-if="showChangeView"
          @click="() => (mobileView = !mobileView)"
          type="is-info"
          >Change Table View</b-button
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import FilterLocation from './FilterLocation';

export default {
  name: 'template-list',
  props: { type: String, columnsData: Array },

  data() {
    return {
      isLoading: false,
      optionsClicked: false,
      showFavorites: false,
      search: '',
      tableData: [],
      perPage: 10,
      page: null,
      isMobile: false,
      mobileView: false,
      pages: [],
      isSearchActive: false,
      filterObject: {},
      updatedTableData: [],
      noFiltersMessage: '',
      showNoFiltersMessage: false,
      cookieFilterChecked: false,
      doneFetching: false,
      isDeletedJobsVisible: false,
      selectedLocations: [],
      lastRoute: null,
    };
  },
  components: {
    'filter-location': FilterLocation,
  },
  computed: {
    productListText() {
      return this.$route.name.includes('editor')
        ? this.$t('product_template_list')
        : this.$t('work_order_list');
    },
    user() {
      return this.$store.state.currentUser;
    },
    sites() {
      return this.$store.state.sites;
    },
    jobs() {
      return this.$store.state.jobs;
    },
    showChangeView() {
      if (
        this.$route.name.includes('inspector') &&
        navigator.maxTouchPoints > 0 &&
        window.orientation < 90
      ) {
        return true;
      }
      return false;
    },
    isMobileDevice() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // true for mobile
        return true;
      }
      // false for not mobile device
      return false;
    },
    deletedJobs() {
      return this.$store.state.deletedJobs;
    },
    isLocationFilterAvailable() {
      const isSuperAdmin = parseInt(this.user.privil, 10) === 1;
      return (
        this.type !== 'editor' &&
        (isSuperAdmin ||
          (this.user.secondaryLocations &&
            this.user.secondaryLocations.length > 0))
      );
    },
    isDeletedJobsAvailable() {
      return (
        (this.type === 'editor' || this.type === 'publisher') &&
        this.user &&
        this.user.role === 'admin'
      );
    },
    waitingForRead() {
      return this.$store.state.waitingForRead;
    },
  },
  async created() {
    if (!this.sites || this.sites.length === 0) {
      await this.$store.dispatch('getSites');
    }
    this.$store.dispatch('clearCurrentJob');
    this.$store.dispatch('setCurrentTasks', {});
    this.checkSearchCookie();
    this.checkPageCookie();
  },

  async mounted() {
    // Visual aid in case there are more items that shown on the list's total
    const container = document.querySelector('nav.pagination');
    const moreElement = document.createElement('small');
    moreElement.classList.add('info');
    moreElement.classList.add('more');
    container.appendChild(moreElement);
    this.checkSearchCookie();
    if (this.type === 'editor') {
      this.checkEditorFavoriteCookie();
    } else {
      this.$cookies.remove('editor_favorite');

      const savedLocations = this.$cookies.get('locations');
      if (savedLocations) {
        this.selectedLocations = savedLocations;
      } else {
        this.selectedLocations = [];
      }
    }
    await this.getJobs();
    const myself = this;
    if (typeof window.orientation !== 'undefined' && window.orientation === 0) {
      this.isMobile = true;
    }
    window.addEventListener('orientationchange', (event) => {
      const orientation = event.target.screen.orientation.angle;
      if (orientation === 0) {
        myself.isMobile = true;

        return true;
      }
      myself.isMobile = false;
    });

    await this.checkFilterCookie();

    if (this.isDeletedJobsAvailable) {
      this.$store.dispatch('getDeletedJobs', { type: this.type });
    }
  },
  methods: {
    filterFunc(row) {
      // eslint-disable-next-line no-restricted-syntax
      const keys = Object.keys(this.filterObject);
      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          let value = row[key];

          if (value == null) return false;
          if (Number.isInteger(value)) {
            if (value !== Number(this.filterObject[key])) return false;
          } else {
            const re = new RegExp(this.filterObject[key], 'i');
            if (typeof value === 'boolean') value = `${value}`;
            if (!value.match(re)) return false;
          }
        }
        return true;
      }
    },
    userOrAdmin(job) {
      if (this.type !== 'editor') return true;
      if (this.user.role === 'admin' && parseInt(this.user.privil, 10) === 1)
        return true;
      if (
        this.user._id === job.prodCreatedByUser.email ||
        this.user._id === job.prodCreatedByUser._id ||
        job.oldOwners?.some((user) => user._id === this.user._id)
      ) {
        return true;
      }
      if (!job?.prodCreatedByUser?.location) return false;

      return false;
    },
    checkSearchCookie() {
      // get search cookie
      const value = this.$cookies.get('search');
      // if cookie has value and search is not defined, set search value
      if (value && !this.search) {
        this.search = value;
      }
    },
    async checkFilterCookie() {
      // get search cookie
      const value = await this.$cookies.get('templates_filter');
      // if cookie has value and search is not defined, set search value
      if (value && this.search && Object.keys(this.filterObject).length === 0) {
        this.filterObject = value;
      }

      this.cookieFilterChecked = true;
    },
    checkPageCookie() {
      // get page cookie
      const value = this.$cookies.get(`${this.type}_page`);
      // if cookie has value and page is not defined, set page value
      if (value && !this.page) {
        this.page = parseInt(value, 10);
      }
      const lastKeyPages = this.$cookies.get(`${this.type}_page_lastKey`);
      if (lastKeyPages) {
        this.pages = lastKeyPages;
      }
    },
    checkEditorFavoriteCookie() {
      // get page cookie
      const value = this.$cookies.get('editor_favorite');
      if (value === 'true' && this.user.favoriteList) {
        this.showFavorites = true;
      } else {
        this.showFavorites = false;
      }
    },
    listFavorites(value) {
      this.showFavorites = value;
      if (value === false) {
        this.$cookies.remove('editor_favorite');
      }
      this.getJobs();
    },
    onPageChange(page) {
      this.page = page;

      this.getJobs();

      if (this.type === 'editor') {
        this.$cookies.set('editor_page', this.page, 60 * 60 * 2);
      } else {
        this.$cookies.set('report_page', this.page, 60 * 60 * 2);
        this.$cookies.set('inspector_page', this.page, 60 * 60 * 2);
        this.$cookies.set('publisher_page', this.page, 60 * 60 * 2);
      }
    },
    async getJobs(forceRender = false, forceFirstPage = false) {
      if (this.search.length > 0) {
        this.isSearchActive = true;
      } else {
        this.isSearchActive = false;
        this.$refs.tableRef.filters = [];
        this.filterObject = {};
      }

      this.doneFetching = false;

      // Save search
      if (this.search) this.$cookies.set('search', this.search, 60 * 60 * 2);

      // If there no page set, set the first page
      if (!this.page) {
        this.page = 1;
      }

      this.$cookies.set(`${this.type}_page`, this.page, 60 * 60 * 2); // expires 8h after

      if (this.showFavorites) {
        this.$cookies.set('editor_favorite', this.showFavorites);
      }

      // Always try to fetch ahead three pages
      const expectedRecordsCount = this.perPage * (this.page + 3);

      const search = {
        type: this.type,
        search: this.search.split(','),
        favorite: this.showFavorites,
        favoriteList: this.user.favoriteList,
        expectedRecordsCount,
      };

      search.locations = this.selectedLocations;

      const result = await this.$store.dispatch('getJobs', search);

      let startIndex;
      if (result.isNewQuery) {
        // Recreate the full table
        this.tableData = [];
        startIndex = 0;
        this.page = 1;
      } else if (forceRender) {
        this.tableData = [];
        startIndex = 0;
        if (forceFirstPage) {
          this.page = 1;
        }
      } else {
        // Just create the new list items
        startIndex = this.tableData.length;
      }

      for (let i = startIndex; i < this.jobs.length; i++) {
        const job = this.jobs[i];
        // eslint-disable-next-line no-await-in-loop
        const thumbnailUrl = this.getThumbnail(job);
        this.tableData.push({
          id: job._id,
          prodCreatedByUser: job.prodCreatedByUser,
          nameId: job.name,
          picture: thumbnailUrl,
          title: job.title,
          product_line: this.getAndFormatDropValues(job.workOrderName),
          original_oem: this.getAndFormatDropValues(job.brand),
          frame_type: this.getAndFormatDropValues(job.frameType),
          model: this.getAndFormatDropValues(job.model),
          part_number: job.userEnteredPartNumber,
          executing_site: this.getExecutingSiteName(job),
          location: this.getJobLocationName(job),
          document_number: job.documentNumber,
          revision: job.revision,
          created_by: this.getCreatedBy(job),
          date: this.getDateString(job),
          completenessClass: this.getCompleteness(job),
          completenessValueFiltered: job.jobCompleted
            ? 0
            : 100 - parseInt(job.completenessPercentage, 10),
          completenessPercentage: job.jobCompleted
            ? '100%'
            : job.completenessPercentage || '0%',
          favoriteCount: job.favoriteCount || 0,
          customer: job.customerInfoText,
          project_number: job.projectInfoText,
          serial_number: job.serialNumber,
          work_order_number: job.workOrderNumber,
          sales_force_opportunity_number: job.salesForceNumber,
          oldOwners: job.oldOwners ? job.oldOwners : [],
          sapReference: job.sapReference,
          owner_date: `${job.prodCreatedByUser.fullname} / ${this.getDateString(
            job
          )}`,
          includesRepairAssessment:
            !!job.repairAssessment && job.repairAssessment.length !== 0,
        });
      }

      // If there are more items inform the user
      const element = document.querySelector('nav.pagination small.info.more');
      if (result.hasMoreData) {
        element.innerText = '+';
      } else {
        element.innerText = '';
      }

      this.doneFetching = true;
    },
    getJobLocationName(job) {
      if (job.prodCreatedByUser && job.prodCreatedByUser.location) {
        const { location } = job.prodCreatedByUser;
        if (typeof location === 'object') {
          return job.prodCreatedByUser.location.siteName;
        }
        return this.$store.getters.siteName(location);
      }
      return '';
    },
    getExecutingSiteName(job) {
      if (job.executingSite) {
        const foundSite = this.sites.find(
          (site) => site._id === job.executingSite
        );
        if (foundSite) {
          return foundSite.siteName;
        }

        console.error(
          `Invalid executingSite '${job.executingSite}' for job ${job._id}`
        );
      }
      return 'N/A';
    },
    getCreatedBy(job) {
      if (job.prodCreatedByUser && job.prodCreatedByUser.fullname) {
        return job.prodCreatedByUser.fullname;
      }
      if (job.modifier && job.modifier.fullname) {
        return job.modifier.fullname;
      }
      return '';
    },
    getDateString(job) {
      let { date } = job;
      const { prodCreatedByUser } = job;
      if (!date) {
        date = prodCreatedByUser.timestamp;
      }
      if (
        !date ||
        (typeof date !== 'number' && Object.keys(date).length === 0)
      ) {
        date = new Date().getTime();
      }
      const dateObject = new Date(parseFloat(date));
      const dateString = `${
        dateObject.getMonth() + 1
      }-${dateObject.getDate()}-${dateObject.getFullYear()}`;

      return dateString;
    },
    getCompleteness(job) {
      const completeness = job.completenessPercentage;
      if (job.jobCompleted) {
        return 'completeness-done';
      }
      if (parseInt(completeness, 10) <= 100 && !job.jobCompleted) {
        return 'completeness-progress';
      }
      return 'completeness-default';
    },
    getThumbnail(job) {
      let url;
      if (!job.thumbnailUrl) {
        job.thumbnailUrl = 'image-empty';
        url = '/img/image-empty.png';
      } else if (job.thumbnailUrl.includes('image-empty')) {
        url = '/img/image-empty.png';
      } else {
        url = job.thumbnailUrl;
      }

      return url;
    },
    getAndFormatDropValues(dropType) {
      let namesFormated = [];
      if (typeof dropType === 'undefined' || dropType === null) return '';
      if (typeof dropType !== 'string' && dropType.length > 1) {
        dropType.forEach((type) => {
          namesFormated.push(type.text);
        });
      } else if (typeof dropType === 'string') {
        namesFormated = dropType;
      } else if (typeof dropType[0] !== 'undefined') {
        namesFormated = dropType[0].text;
      } else {
        namesFormated = '';
      }
      if (typeof namesFormated === 'string') {
        return namesFormated;
      }
      if (Array.isArray(namesFormated)) {
        return namesFormated.join(', ');
      }
      return '';
    },
    goToJob({ id }) {
      setTimeout(() => {
        if (this.optionsClicked) {
          this.optionsClicked = false;
          return false;
        }
        if (this.type !== 'report') {
          this.$router.push({
            name: `${this.type}-tasks`,
            params: { id },
          });
        } else {
          this.$router.push({
            name: `${this.type}-template`,
            params: { id },
          });
        }
      }, 200);
    },
    async addToFavorites(id, nameId, count) {
      this.optionsClicked = true;
      if (this.user.favoriteList && this.user.favoriteList.includes(nameId)) {
        await this.$store.dispatch('removeFavoriteTemplate', {
          id,
          nameId,
          count,
        });
      } else {
        await this.$store.dispatch('addFavoriteTemplate', {
          id,
          nameId,
          count,
        });
      }
      this.getJobs();
    },
    favoriteStarClass(nameId) {
      if (
        this.user?.favoriteList?.length > 0 &&
        this.user.favoriteList.includes(nameId)
      ) {
        return 'favorite-star active';
      }
      return 'favorite-star';
    },
    async duplicate(id) {
      const index = this.jobs.findIndex((job) => job._id === id);
      if (index < 0) return false;

      const job = this.jobs[index];
      await this.$store.dispatch('duplicateJob', job);
      return this.getJobs(true);
    },
    async deleteJob(id, prodline) {
      this.$buefy.dialog.confirm({
        title: 'DELETE',
        message: `Please confirm deletion of ${prodline}`,
        confirmText: 'Delete',
        type: 'is-dark',
        hasIcon: true,
        icon: 'delete',
        onConfirm: async () => {
          this.isLoading = true;
          await this.$store.dispatch('deleteJob', id);
          await this.getJobs(true);
          this.isLoading = false;
        },
      });
    },
    copyFrom(id) {
      if (this.type === 'editor') {
        const jobIndex = this.jobs.findIndex((item) => item._id === id);
        const job = { ...this.jobs[jobIndex] };
        this.$store.commit('SET_CURRENT_JOB', job);
        this.$router.push({ name: 'editor-copyfrom', params: { id } });
      } else {
        const jobIndex = this.jobs.findIndex((item) => item._id === id);
        const job = { ...this.jobs[jobIndex] };
        this.$store.commit('SET_CURRENT_JOB', job);
        this.$router.push({ name: 'publisher-copyfrom', params: { id } });
      }
    },
    editJob(id) {
      if (this.type === 'editor') {
        const jobIndex = this.jobs.findIndex((item) => item._id === id);
        const job = { ...this.jobs[jobIndex] };
        this.$store.commit('SET_CURRENT_JOB', job);
        this.$router.push({ name: 'editor-edit-job', params: { id } });
      } else {
        const jobIndex = this.jobs.findIndex((item) => item._id === id);
        const job = { ...this.jobs[jobIndex] };
        this.$store.commit('SET_CURRENT_JOB', job);
        this.$router.push({ name: 'publisher-edit-order', params: { id } });
      }
    },
    formatColumnName(columnName) {
      const words = columnName.split('_');
      words.forEach((word, index) => {
        words[index] =
          words[index].charAt(0).toUpperCase() + words[index].slice(1);
      });
      return words.join(' ');
    },

    clearFilters() {
      this.filterObject = {};
      this.$cookies.remove('templates_filter');
    },
    async updateLocation(newListLocations) {
      this.selectedLocations = newListLocations;

      await this.getJobs();
    },
    showDeletedJobs() {
      this.isDeletedJobsVisible = true;
    },
    hideDeletedJobs() {
      this.isDeletedJobsVisible = false;
    },
    async undelete(jobId) {
      await this.$store.dispatch('undeleteJob', jobId);

      this.getJobs(true, true);
    },
    convertEpochToDate(epoch) {
      const date = new Date();
      date.setTime(epoch * 1000);
      return date
        .toISOString()
        .slice(0, 19)
        .replace(/-/g, '/')
        .replace('T', ' ');
    },
  },
  watch: {
    search: {
      handler(newValue) {
        // eslint-disable-next-line eqeqeq
        if (newValue == '') {
          this.$cookies.remove('search');
          this.page = 1;
          return this.getJobs();
        }
      },
    },
    tableData: {
      deep: true,
      async handler(newValue) {
        if (Object.keys(this.filterObject).length > 0) {
          if (!this.cookieFilterChecked) await this.checkFilterCookie();

          this.updatedTableData = newValue.filter((row) =>
            this.filterFunc(row)
          );

          if (
            this.doneFetching &&
            this.jobs.length > 0 &&
            this.updatedTableData.length === 0
          ) {
            this.showNoFiltersMessage = true;

            this.noFiltersMessage =
              'Search returned no results. Clear filters to get data.';
          }
        } else {
          this.updatedTableData = newValue;
        }
      },
    },
    filterObject: {
      handler(newVal) {
        const keys = Object.keys(newVal);
        if (keys.length > 0) {
          const objectEntries = Object.entries(newVal);
          if (!objectEntries.some((key_value) => key_value[1] !== '')) {
            this.filterObject = {};
            this.$cookies.remove('templates_filter');
          } else {
            this.updatedTableData = this.tableData.filter((row) =>
              this.filterFunc(row)
            );

            this.$cookies.set('templates_filter', newVal, 60 * 60 * 2);
          }
        } else {
          this.$cookies.remove('templates_filter');
          this.updatedTableData = this.tableData;
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  font-family: 'Siemens Sans';
  display: inline-flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 50px;
  background-color: #fafafa;
  font-size: 18px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  color: #333;
  padding-left: 20px;
  .right-section {
    float: right;
    display: inline-flex;
    .search {
      height: 100%;
      padding: 5px 20px;
      background-color: #e0e0e0;
      .search-input input {
        border: 0px !important;
        background-color: #e0e0e0 !important;
        outline: none;
        box-shadow: none;
      }
    }
  }
  .list-type {
    cursor: pointer;
    height: 50px;
    text-align: center;
    line-height: 50px;
    margin: 0 20px;
    min-width: 150px;
    float: left;
    &.active {
      border-bottom: 3px solid #4a86e8;
    }
  }
}
.items {
  height: calc(100vh - 100px) !important;
  overflow-y: auto;
  .items-container {
    padding: 45px 30px 10px 30px;
  }
}
img.thumbnail {
  width: 130px;
  min-width: 130px;
  height: 60px;
}
.completeness-default,
.completeness-done,
.completeness-progress {
  border-left: solid 8px;
  position: relative;
  .completeness-inner {
    content: '';
    height: 0%; //You can change this if you want smaller/bigger borders
    width: 8px;
    position: absolute;
    left: -8px;
    top: 0; // If you want to set a smaller height and center it, change this value
    background-color: #aaa; // The color of your border
  }
}
.completeness-default {
  border-left-color: #aaa;
}
.completeness-done {
  border-left-color: rgb(123, 249, 109);
}
.completeness-progress {
  border-left-color: #fcb95b;
}
.favorite-star {
  color: #ccc;
  margin: 2px;
  cursor: pointer;
  &:hover {
    color: #ffdd00;
  }
  &.active {
    color: #ffdd00;
    &:hover {
      color: #ccc;
    }
  }
}
.options-column {
  cursor: default;
  min-width: 120px;
  div {
    display: inline-block;
    margin: 0 3px;
    line-height: 2.5;
    button {
      color: #ccc;
      &:hover {
        color: black;
      }
      &:active {
        color: blue;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
  .options-more-btn {
    border: none;
  }
}

.text-column-limit {
  line-height: 20px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 5;
  -moz-line-clamp: 5;
  -ms-line-clamp: 5;
  line-clamp: 5;
  overflow: hidden;
  transition: 0s overflow;
  transition: 0s display;

  &:hover {
    width: 300px;
    overflow: visible;
    display: table;

    transition-delay: 1s;
  }
}

.image-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.7;
}

$tooltip-multiline-sizes: (
  small: 180px,
  medium: 240px,
  large: 400px,
);
$tooltip-colors: blue;

.filter-location {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.dij-jobs-recycle-bin.button.is-success {
  border-left: 1px solid;
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
}

.dij-jobs-deleted-modal .modal-card {
  width: 960px;

  .modal-card-body {
    font-size: 16px;
  }
}
</style>
