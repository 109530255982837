<template>
  <div
    class="table-content"
    :class="{ 'cal-height': displayType === 'preview' }"
  >
    <table v-if="tableData">
      <thead :class="{ 'edit-mode': isEditFirstColumnEditable }">
        <tr>
          <th
            v-if="isPartialEditMode || isFullEditMode"
            style="text-align: center"
            @click="toggleEditMode"
            class="table-cell"
          >
            <b-button type="is-success is-light" size="is-small">Edit</b-button>
          </th>
          <th v-else class="table-cell"></th>
          <th
            v-for="(header, index) in tableData.length > 0
              ? tableData[0].slice(1)
              : []"
            :key="`head-${index}`"
            class="table-cell"
            style="text-align: center"
            :contenteditable="isEditFirstColumnEditable"
            @input="handleInput($event, 0, index + 1)"
          >
            {{ header }}
          </th>
        </tr>
      </thead>
      <transition-group name="row-bounce" tag="tbody">
        <tr
          v-for="(row, rowIndex) in tableData.slice(1)"
          :key="`row-${rowIndex}`"
          :class="{
            'odd-row': rowIndex % 2 !== 0,
            'even-row': rowIndex % 2 === 0,
          }"
        >
          <td
            v-for="(cell, colIndex) in row"
            :key="`cell-${rowIndex}-${colIndex}`"
            class="table-cell"
            :class="{
              'editable-cell': isEditFirstColumnEditable && colIndex === 0,
              'editable-cell-background':
                isEditFirstColumnEditable && colIndex === 0,
            }"
            :contenteditable="isCellContentEditable(colIndex)"
            @input="handleInput($event, rowIndex + 1, colIndex)"
            @focus="handleFocus($event)"
            @blur="onCellLeave"
          >
            {{ cell }}
          </td>
        </tr>
      </transition-group>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Number,
      required: true,
    },
    cols: {
      type: Number,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    displayType: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCSV: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isInspectorMode() {
      return this.displayType === 'inspector';
    },
    isPartialEditMode() {
      return this.displayType === 'partialEdit';
    },
    isFullEditMode() {
      return this.displayType === 'fullEdit';
    },
  },
  mounted() {
    this.isEditFirstColumnEditable = false;

    this.tableData = this.data;
    this.initTempData();
    if (this.tableData.length === 0 || this.displayType === 'inspector') {
      this.createTableData();
    } else {
      this.initTempData();
    }
  },
  watch: {
    rows() {
      this.createTableData();
    },
    cols() {
      this.createTableData();
    },

    data(newVal) {
      if (newVal.length !== 0) {
        this.tableData = newVal;
      }
    },
  },
  data() {
    return {
      isEditFirstColumnEditable: false,
      debounceTimer: null,
      originalData: [],
      tableData: [],
      tempTableData: [],
      rowsAndColsInitialized: false,
    };
  },
  methods: {
    onCellLeave() {
      this.$emit('onCellLeave');
    },
    getTempTableData() {
      return this.tempTableData;
    },
    initTempData() {
      this.tempTableData = this.tableData.map((row) => [...row]);
    },
    handleInput(event, rowIndex, colIndex) {
      this.tempTableData[rowIndex][colIndex] = event.target.innerText;
    },
    handleFocus(event) {
      const range = document.createRange();
      range.selectNodeContents(event.target);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    },
    isCellContentEditable(colIndex) {
      if (this.readOnly) {
        return false;
      }

      if (this.isFullEditMode) {
        return true;
      }

      if (
        this.isEditFirstColumnEditable &&
        this.isPartialEditMode &&
        colIndex === 0
      ) {
        return true;
      }

      if (this.isInspectorMode && colIndex !== 0) {
        return true;
      }

      return false;
    },
    toggleEditMode() {
      this.isEditFirstColumnEditable = !this.isEditFirstColumnEditable;
      if (!this.isEditFirstColumnEditable) {
        this.tableData = this.tempTableData.map((row) => [...row]);
        this.$emit('tableDataChanged', this.tableData);
      }
    },
    createTableData() {
      const oldTableData = [...this.tempTableData];
      const headers = ['', ...Array.from({ length: this.cols }, () => '-')];

      // Initialize tableData with new structure
      this.tableData = [headers].concat(
        Array.from({ length: this.rows }, (_, rowIndex) =>
          Array.from({ length: this.cols + 1 }, (colValue, colIndex) =>
            colIndex === 0 ? String(rowIndex + 1) : ''
          )
        )
      );

      // Overwrite the newly created tableData with data
      // from oldTableData where applicable
      oldTableData.forEach((row, rowIndex) => {
        row.forEach((cell, cellIndex) => {
          if (
            this.tableData[rowIndex] &&
            this.tableData[rowIndex][cellIndex] !== undefined
          ) {
            this.tableData[rowIndex][cellIndex] = cell;
          }
        });
      });

      // Remove the last column and last row in case of CSV because they are additional
      if (this.isCSV) {
        // Remove the last column from each row
        this.tableData = this.tableData.map((row) => row.slice(0, -1));

        // Remove the last row
        this.tableData.pop();
      }

      this.initTempData();
      this.$emit('tableDataChanged', this.tableData);
    },
  },
};
</script>

<style scoped>
.table-content {
  overflow: scroll;
  overflow-x: hidden;
  width: 100%;
}

.cal-height {
  max-height: calc(100vh - 570px);
}

.row-bounce-enter-active {
  animation: bounceIn 0.8s ease forwards;
}

.row-bounce-leave-active {
  transition: opacity 0.1s ease;
  opacity: 0;
}

.edit-mode,
.editable-cell-background {
  background-color: #e0ffe4;
}

thead.edit-mode th {
  background-color: #e0ffe4;
}
table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  border: #f5f5f5 3px solid;
}

.table-cell {
  padding: 8px;
  text-align: left;
  box-sizing: border-box;
}

.table-cell:not(:last-child) {
  border-right: 1px solid #ddd;
}

.table-cell:first-child {
  width: 100px;
  text-align: center;
  overflow: hidden;
}

.odd-row {
  background-color: #ffffff;
}

.even-row {
  background-color: #dddddd48;
}

@keyframes bounceIn {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  30% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-10px);
  }

  80% {
    transform: translateY(-0px);
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
