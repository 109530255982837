<template>
  <div id="app">
    <div
      v-if="
        !this.$route.path.match('callback|signin|logout|new-user-welcome-page')
      "
    >
      <b-notification
        v-if="announcementBannerVisible"
        class="dij-announcement-banner"
        has-icon
        aria-close-label="Close banner"
        @close="hideAnnouncementBanner"
        :message="announcementBannerMessage"
      >
      </b-notification>
      <Header />
    </div>
    <div class="notification-area">
      <b-notification
        v-if="notificationVisible"
        :type="notificationType"
        :auto-close="notificationAutoClose"
        :duration="notificationAutoCloseDuration"
        aria-close-label="Close notification"
        has-icon
        role="alert"
        @close="hideNotification"
      >
        {{ notificationMessage }}
      </b-notification>
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import Header from './components/layout/Header';

export default {
  name: 'App',
  data() {
    return {
      parsedUser: {},
      tools: ['admin', 'editor', 'publisher', 'inspector', 'report'],
      sessionStorageStatus: false,
      keyToRefresh: 0,
      showRefreshMessage: false,
      notificationVisible: false,
      notificationMessage: null,
      notificationType: null,
      notificationAutoClose: false,
      notificationAutoCloseDuration: 0,
    };
  },
  components: {
    Header,
  },
  computed: {
    user() {
      return this.$store.state.currentUser;
    },
    userWithoutSite() {
      return this.$store.state.userWithoutSite;
    },
    successNotificationVisible() {
      return (
        this.notificationVisible === true &&
        this.notificationType === 'is-success'
      );
    },
    warningNotificationVisible() {
      return (
        this.notificationVisible === true &&
        this.notificationType === 'is-warning'
      );
    },
    errorNotificationVisible() {
      return (
        this.notificationVisible === true &&
        this.notificationType === 'is-danger'
      );
    },
    announcementBannerMessage() {
      return this.$store.state.announcementBannerMessage;
    },
    announcementBannerVisible() {
      const now = new Date();
      return (
        this.$store.state.announcementBannerVisible &&
        this.$store.state.announcementBannerMessage &&
        this.$store.state.announcementBannerStartDate <= now &&
        this.$store.state.announcementBannerEndDate > now
      );
    },
  },
  async beforeUpdate() {
    // Try to get the announcement banner every update
    if (this.user && !this.user.isPendingApproval && !this.user.isRejected) {
      this.$store.dispatch('getAnnouncementBanner');
    }
  },
  async mounted() {
    // transfers sessionStorage from one tab to another
    const myself = this;
    const sessionStorage_transfer = (event) => {
      if (!event) {
        event = window.event;
      } // ie suq
      if (!event.newValue) return; // do nothing if no value to work with
      if (event.key === 'getSessionStorage') {
        // another tab asked for the sessionStorage -> send it
        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
      } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
        // another tab sent data <- get it
        const data = JSON.parse(event.newValue);
        Object.keys(data).forEach((key) => {
          sessionStorage.setItem(key, data[key]);
        });
        myself.sessionStorageStatus = true;
      }
    };

    window.addEventListener(
      'refreshPolymer',
      () => {
        myself.keyToRefresh += 1;
        myself.$forceUpdate();
      },
      false
    );

    // TODO SWITCH THIS FOR ERROR NOTIFICATION
    // Error message
    // Your session is expired and your page will be refreshed.
    window.addEventListener('showRefreshMessage', () => {
      this.notificationType = 'is-danger';
      this.notificationMessage =
        'Your session is expired and your page will be refreshed.';
      this.notificationAutoClose = false;
      this.notificationVisible = true;
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    });

    // listen for changes to localStorage
    if (window.addEventListener) {
      window.addEventListener('storage', sessionStorage_transfer, false);
    } else {
      window.attachEvent('onstorage', sessionStorage_transfer);
      // window.attachEvent('onstorage', sessionStorage_transfer);
    }

    // Ask other tabs for session storage (this is ONLY to trigger event)
    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStorage', 'foobar');
      localStorage.removeItem('getSessionStorage', 'foobar');
    } else {
      this.sessionStorageStatus = true;
    }

    // Notification message handlers
    // Info messages only override themselfs
    this.$store.watch(
      () => {
        return this.$store.state.infoMessage;
      },
      (infoMessage) => {
        if (
          infoMessage &&
          !this.successNotificationVisible &&
          !this.warningNotificationVisible &&
          !this.errorNotificationVisible
        ) {
          this.notificationType = 'is-info';
          this.notificationMessage = infoMessage;
          this.notificationAutoClose = true;
          this.notificationAutoCloseDuration = 3000;
          this.notificationVisible = true;
        }
      }
    );
    // Success messages override info, themselfs but not warning and error messages
    this.$store.watch(
      () => {
        return this.$store.state.successMessage;
      },
      (successMessage) => {
        if (
          successMessage &&
          !this.warningNotificationVisible &&
          !this.errorNotificationVisible
        ) {
          this.notificationType = 'is-success';
          this.notificationMessage = successMessage;
          this.notificationAutoClose = true;
          this.notificationAutoCloseDuration = 3000;
          this.notificationVisible = true;
        }
      }
    );
    // Warning messages override info, success and themselfs but not error messages
    this.$store.watch(
      () => {
        return this.$store.state.warningMessage;
      },
      (warningMessage) => {
        if (warningMessage && !this.errorNotificationVisible) {
          this.notificationType = 'is-warning';
          this.notificationMessage = warningMessage;
          this.notificationAutoClose = true;
          this.notificationAutoCloseDuration = 10000;
          this.notificationVisible = true;
        }
      }
    );
    // Error messages overrides every message type
    this.$store.watch(
      () => {
        return this.$store.state.errorMessage;
      },
      (errorMessage) => {
        if (errorMessage) {
          this.notificationType = 'is-danger';
          this.notificationMessage = errorMessage;
          this.notificationAutoClose = false;
          this.notificationVisible = true;
        }
      }
    );
  },

  methods: {
    hideNotification() {
      this.notificationVisible = false;
      this.notificationMessage = null;
      this.$store.dispatch('clearAllMessages');
    },
    hideAnnouncementBanner() {
      this.$store.dispatch('hideAnnouncementBanner');
    },
  },
};
</script>

<style>
@font-face {
  font-family: 'Siemens Sans';
  src: url(/fonts/siemenswebfonts/sisan06.ttf) format('truetype');
}

@font-face {
  font-family: 'Siemens Roman';
  src: url(/fonts/siemenswebfonts/sisan03.ttf) format('truetype');
}

.notification-area {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  top: 103px;
}

.notification-area div.media {
  align-items: center;
}

.notification.dij-announcement-banner {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  background-color: rgb(255, 224, 138);
}
</style>
